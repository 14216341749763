
import { SiteStation, SiteTypes } from "@/store/modules/site/site.types";
import { Pagination } from "@/types/types";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const siteX = namespace("Site");

@Component({})
export default class SiteSelect extends Vue {
  @Prop() public label?: string;
  @Prop() public filled?: boolean;
  @Prop() public outlined?: boolean;
  @Prop() public dense?: boolean;
  @Prop() public hideDetails?: boolean;
  @Prop() public rules?: unknown[];
  @Prop() value?: number;

  @siteX.Action(SiteTypes.LOAD_SITES)
  public getSites!: (pagination: Pagination) => void;

  @siteX.State(SiteTypes.SITES)
  public sites!: SiteStation[];

  @siteX.State(SiteTypes.LOADING_SITES_STATE)
  public loadingSites!: boolean;

  @Emit("input")
  save(id: string): string {
    return id;
  }

  mounted(): void {
    this.getSites({ page: 1, limit: 10 });
  }
}
